import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import '../newRoadmap.css'
import icon from '../assets/rmI.png';

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
  function isScrollIntoView(elem, index) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(window).height() * .5;
    if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
      $(elem).addClass('active');
    }
    if (!(elemBottom <= docViewBottom)) {
      $(elem).removeClass('active');
    }
  }
  var timeline = $('.vertical-scrollable-timeline li');
  Array.from(timeline).forEach(isScrollIntoView);
});
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
  function isScrollIntoView(elem, index) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(window).height() * .5;
    if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
      $(elem).addClass('active');
    }
    if (!(elemBottom <= docViewBottom)) {
      $(elem).removeClass('active');
    }
  }
  var timeline = $('.vertical-scrollable-timeline li');
  Array.from(timeline).forEach(isScrollIntoView);
});


class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Rm">

        <div class="storyH">Ninja Roadmap</div>
        <div class="storyH2">Club Of Shadows</div>

        <div class="timeline-container">
          <ul class="vertical-scrollable-timeline">
            <li>
              <h2>Phase 1</h2>
              <div class="rmSub">PRE MINT</div>
              <p class="rmP"><img class="rmNote" src={icon}/>Social media marketing</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Website </p>
              <p class="rmP"><img class="rmNote" src={icon}/>Giveaways and free NFT roles, Whitelisting, OG Listing</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Launch the collection</p>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />

              </div>
            </li>
            <li>
              <h2>Phase 2</h2>
              <div class="rmSub">AFTER MINT</div>

              <p class="rmP"><img class="rmNote" src={icon}/>Crypto signal channel -Expert will give holders their trading tips</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Include holders in the community by voting and other communication bridges.</p>
              <p class="rmP"><img class="rmNote" src={icon}/>For current holders there will be giveaways in the form of crypto, exclusive airdrops and other big surprises.</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Marketing for holders - Show your business or talent to thousands of people</p>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>
            <li>
              <h2>Phase 3</h2>
              <div class="rmSub">AFTER MINT</div>

              <p class="rmP"><img class="rmNote" src={icon}/>25.000$ towards Anti-Bulliying</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Upgrade website and discord to give an maximizing experience Channel for holders to learn to make money online - Full guides and expert tips</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Channel for holders to network and find new beneficial contacts.</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Youtube project including holders NFTs.</p>

              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>

            <li>
              <h2>Phase 4:</h2>
              <div class="rmSub">AFTER MINT</div>

              <p class="rmP"><img class="rmNote" src={icon}/>Metaverse! Buying land in the metaverse and giving our ninjas a home.</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Selected diamond holders will start planning Roadmap 2.0 with the CoS team.</p>
              <p class="rmP"><img class="rmNote" src={icon}/>CoS collab to generate more benefits to holders by holding a Shadow Ninja</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Robot Ninja Collection (Diamond holders split on 30% of prof )</p>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>

            <li>
              <h2>Phase 5:</h2>
              <div class="rmSub">AFTER MINT</div>

              <p class="rmP"><img class="rmNote" src={icon}/>Big metaverse event</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Holders benefits</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Clothing/accessories for holders</p>
              <p class="rmP"><img class="rmNote" src={icon}/>Diamond holder benefits</p>                
              <p class="rmP"><img class="rmNote" src={icon}/>Reveal of Roadmap 2.0 </p>
              <div class="rmNote2">In Roadmap 2.0 there will be a 3D Ninja collection </div>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>
          </ul>
        </div>

      </div>
    )
  }
}

export default Team;